import React, {useEffect, useState} from 'react'
import axios from "axios"
import { Container } from 'reactstrap'

import MiniHero from '../components/MiniHero'
import LoadingSpinner from '../components/LoadingSpinner'
import Footer from '../components/Footer'

const Testimonials = (props) => {

  const [testimonials, setTestimonials] = useState(null)

  useEffect(
    () => {
      getTestimonials()
    }
    ,[]
  )

  async function getTestimonials() {
    try {
      const response = await axios.get('/.netlify/functions/airtable3', { headers: { Accept: 'application/json' } })
      const data = response.data.msg.records
      console.log(data)
      setTestimonials({
        statusCode: 200,
        data: data
      })
    } catch (err) {
      console.log(err) // output to netlify function log
      setTestimonials({
        statusCode: 500,
        error: err.message // Could be a custom message or object i.e. JSON.stringify(err)
      })
    }

  }


  return (
    <>
      <Container id="page page-testimonials" fluid={true}>
        <MiniHero />
        <Container>
          <div className="content">
            <h1>Testimonials</h1>
            <p>People sometimes really want to tell us how well we're doing.  We thought we'd share some of them with you!</p>
            {testimonials && testimonials.data && (
              <ul className="testimonials-list">
                {testimonials.data.map(item => (
                  <li key={item.id}>
                    <h3>{item.fields.title}</h3>
                    {item.fields.attachments.map((attachment, index) => (
                      <img key={index} src={attachment.url} alt="client testimonial" />
                    ))}
                  </li>
                ))}
              </ul>
            )}
            {!testimonials &&
              <LoadingSpinner />
            }
          </div>
        </Container>
        <Footer />
      </Container>
    </>
  );
}

export default Testimonials
