import React from 'react'
//import { Router, Link } from "@reach/router"
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import 'typeface-roboto'

import 'bootstrap/dist/css/bootstrap.min.css'
import './scss/themes/light.scss';

import Home from './pages/Home'
import Testimonials from './pages/Testimonials'
import CarrierProducts from './pages/CarrierProducts'
import Financing from './pages/Financing'

const history = createBrowserHistory()

const App = (props) => {

    return (
      <div id="app" className="light">
        <Router history={history}>
          <Switch>
            <Route path="/testimonials" component={Testimonials} />
            <Route exact path="/products" component={CarrierProducts} />
            <Route path="/products/:category" component={CarrierProducts} />
            <Route path="/financing" component={Financing} />
            <Route path="/" component={Home} />
            <Route component={Home} />
          </Switch>
        </Router>
      </div>
    )
}

export default App
