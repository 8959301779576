import React from 'react'
import { Container } from 'reactstrap'

import Hero from '../components/Hero'
import Content from '../components/Content'
import Footer from '../components/Footer'

const Home = (props) => {

    return (
      <>
        <Container id='page' fluid={true}>
          <Hero />
          <Content />
          <Footer />
        </Container>
      </>
    )
}

export default Home
