import React, {useState} from 'react'
import { NavLink } from 'react-router-dom'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem } from 'reactstrap'

const NavRow = (props) => {

  const [isOpen, toggleOpen] = useState(false)

  function toggle() {
    toggleOpen(!isOpen)
  }

    return (
        <Navbar color="light" light expand="md">
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink to="/">Home</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/testimonials">Testimonials</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/products">Carrier Products</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/financing">Financing</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>      
    )
}

export default NavRow
