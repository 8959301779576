import React, { useEffect } from "react";
import { Container } from "reactstrap";
import Iframe from 'react-iframe'

import MiniHero from "../components/MiniHero";
import Footer from "../components/Footer";


const Testimonials = props => {
  useEffect(() => {}, []);

  return (
    <>
      <Container className="page page-testimonials" fluid={true}>
        <MiniHero />
        <Container>
          <div className="content">
            <h1>Our Financing Options For Oregon and Washington Homeowners</h1>
            <h2>Finance Your Energy Efficiency Upgrade And Start Saving Today!</h2>
            <p>
              Now it's easier than ever to save money and enjoy a healthier, more comfortable home! 
              At P&amp;L Johnson Mechanical, Inc, we offer easy-to-use financing options for each of our clients. During our in-house visit, your home energy specialist can explain how our financing can work for you!
            </p>
            <p>
              We offer the following financing options throughout Saint Helens, Warrenton, Astoria, Seaside, Forest Grove and surrounding areas.
            </p>

            <Iframe url="/form.html"
              width="100%"
              height="500px"      
              id="financeForm"
              display="initial"
              position="relative"
            />
            
          </div>
        </Container>
        <Footer />
      </Container>
    </>
  );
};

export default Testimonials;
