import React from 'react'
import { Row, Col } from 'reactstrap'
import pnlj from '../assets/pnlj-logo.svg'
import carrierLogo from '../assets/carrier-corp-logo.jpg'
import carrierAuthLogo from '../assets/carrier-auth-dealer-logo.jpg'

const Header = (props) => {

    return (
      <Row id="header">
        <Col></Col>
        <Col xs={12} lg={3} className="col-carrier">
          <a href="https://www.carrier.com">
            <img src={carrierLogo} alt="Carrier" className="logo logo-carrier"/>
          </a>
        </Col>
        <Col xs={12} lg={4} className="col-pnlj">
          <a href="/">
            <img src={pnlj} alt="P and L Johnson Mechanical Logo" className="logo logo-pnlj"/>
          </a>
        </Col>
        <Col xs={12} lg={3} className="col-auth-logo">
          <a href="https://www.carrier.com">
            <img src={carrierAuthLogo} alt="Carrier" className="logo logo-carrier"/>
          </a>
        </Col>
        <Col></Col>
      </Row>
    )
}

export default Header
