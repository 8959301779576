import React, {useEffect, useState} from 'react'
import axios from "axios"
import { Container } from 'reactstrap'
import ReactMarkdown from 'react-markdown'

import MiniHero from '../components/MiniHero'
import CarrierNavRow from '../components/CarrierNavRow'
import LoadingSpinner from '../components/LoadingSpinner'
import Footer from '../components/Footer'

const CarrierProducts = (props) => {

  const [products, setProducts] = useState([])
  const now = new Date()
  const nowStr = `${now.getFullYear()}-${(now.getMonth()+1).toString().padStart(2, '0')}-${(now.getDate()).toString().padStart(2, '0')}`
  const parsedNow = new Date(nowStr)

  useEffect(
    () => {
      getTestimonials(props.match.params.category || 'gas-furnaces')
    }
    ,[props.match.params]
  )

  async function getTestimonials(category) {
    try {
      const response = await axios.get(`/.netlify/functions/products?category=${category}`, { headers: { Accept: 'application/json' } })
      const data = response.data.msg.records
      setProducts(data)
    } catch (err) {
      // output to netlify function log
      console.error(err)
    }
  }

  function renderItem(item, index, arr) {

    if (item.fields.Starts && item.fields.Expires) {
      const start = new Date(item.fields.Starts)
      const expires = new Date(item.fields.Expires)

      if (parsedNow < start || parsedNow > expires) {
        return ''
      }
    }

    switch (item.fields.Type) {
      case 'Category':
        return <div className="product-line" key={item.id} >
          <h3>{item.fields.Name}</h3>
        </div>
      case 'Item':
        return <a href={item.fields.Link} target="_blank" rel="noopener noreferrer" key={item.id} >
          <div className="product-item">
            <h4>{item.fields.Name}</h4>
            {item.fields.Image && item.fields.Image.length > 0 &&
              <img className="product-image" src={item.fields.Image[0].thumbnails.large.url} alt={'model ' + item.fields.Model} />
            }
            {item.fields.Model &&
              <div className="model-number">Model: {item.fields.Model}</div>
            }
            <div className="product-body">
            {item.fields.Features &&
              <ReactMarkdown source={item.fields.Features} />
            }
            {item.fields.Warranty &&
              <><b>Warranty:</b> <ReactMarkdown source={item.fields.Warranty} /></>
            }
            </div>
          </div>
        </a>
      case 'Promotion':
        return <div className="product-promotion" key={item.id} >
          <ReactMarkdown source={item.fields.Name} />
            {item.fields.Link &&
              <a href={item.fields.Link}>&gt; Click here for more information</a>
            }
        </div>
      //no default
    }
  }


  return (
    <>
      <Container id="page page-testimonials" fluid={true}>
        <MiniHero />
        <Container>
          <div className="content">
            <h1>Carrier Products</h1>
            <div>We are all about your comfort. And that includes making you feel comfortable about your decision to purchase a Carrier system. That’s why Carrier Factory Authorized Dealers offer a 100% satisfaction guarantee. Look for the Factory Authorized Dealer icon next to the business name when searching for a dealer and ask them for important terms and details.</div>
            <br/>
            <CarrierNavRow />

            {products && (
              <div className="product-list">
                {products.map((item, index, arr)  =>
                  renderItem(item, index, arr)
                )}
              </div>
            )}
            {!products.length &&
              <LoadingSpinner />
            }
          </div>
        </Container>
        <Footer />
      </Container>
    </>
  )
}

export default CarrierProducts
