import React from 'react'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'

const EmailUsButton = (props) => {

    return (
      <Button 
        color="secondary" 
        size="lg"
        tag={Link} to="/financing"
      >Email Us Today</Button>
    )
}

export default EmailUsButton
