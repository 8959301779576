import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTruck, faTrophy, faUsers, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'
//import { Button } from 'reactstrap'

const Content = (props) => {

  return (
    <div id="content">
      <Row className="bg1">
        <Col>
          <Container id="contentContainer">
            <Row>
              <Col>
                <span>
                  Your experts for HVAC Service, Repair and Replacement
                </span>
                <h1>Astoria's Heating &amp; Air Conditioning Company</h1>
                <p>
                  We know how important it is to have a comfortable home. When
                  your heating or cooling system stops working or needs an
                  upgrade, P&amp;L Johnson Mechanical, Inc can help. We make it
                  easy to service, replace, or install your HVAC system. Plus,
                  we back all of our services with a{" "}
                  <a href="#conditions">
                    100% money-back guarantee on Carrier® installations
                  </a>
                  .
                </p>
                <p>
                  P&amp;L Johnson Mechanical, Inc services all brands of central
                  air conditioners, furnaces, heat pumps, and boilers. If you’re
                  looking to install a new HVAC system, we can help you choose
                  the right heating and air conditioning equipment for your
                  needs and budget.
                </p>
                <p>
                  If you need your furnace or air conditioner serviced, call us
                  today to ask about same day service, or get started on your
                  system installation or replacement with a free in-home
                  consultation.
                </p>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row className="bg1-l">
        <Col>
          <Container id="contentContainer">
            <Row>
              <Col>
                <span>Service, Repair and More</span>
                <h1>We're a Carrier Factory Authorized Dealer</h1>
                <p>
                  We know how important it is to have a comfortable home. When
                  your heating or cooling system stops working or needs an
                  upgrade, P&amp;L Johnson Mechanical, Inc can help. We make it
                  easy to service, replace, or install your HVAC system. Plus,
                  we back all of our services with a{" "}
                  <a href="#conditions">
                    100% money-back guarantee on Carrier® installations
                  </a>
                  .
                </p>
                <p>
                  P&amp;L Johnson Mechanical, Inc services all brands of central
                  air conditioners, furnaces, heat pumps, and boilers. If you’re
                  looking to install a new HVAC system, we can help you choose
                  the right heating and air conditioning equipment for your
                  needs and budget.
                </p>
                <p>
                  If you need your furnace or air conditioner serviced, call us
                  today to ask about same day service, or get started on your
                  system installation or replacement with a free in-home
                  consultation.
                </p>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row className="bg2-l">
        <Col>
          <Container id="contentContainer">
            <Row>
              <Col md={6}>
                <span>We serve the following areas</span>
                <h1>Our Service Area</h1>
                <p>
                  <b>Washington</b>
                </p>
                <ul className="city-scroll-list">
                  <li>Cathlamet</li>
                  <li>Chinook</li>
                  <li>Grays River</li>
                  <li>Ilwaco</li>
                  <li>Long Beach</li>
                  <li>Naselle</li>
                  <li>Ocean Park</li>
                  <li>Rosburg</li>
                </ul>
                <p>
                  <b>Oregon</b>
                </p>
                <ul className="city-scroll-list">
                  <li>Arch Cape</li>
                  <li>Astoria</li>
                  <li>Bay City</li>
                  <li>Cannon Beach</li>
                  <li>Clatskanie</li>
                  <li>Cloverdale</li>
                  <li>Garibaldi</li>
                  <li>Hammond</li>
                  <li>Hebo</li>
                  <li>Manzanita</li>
                  <li>Nehalem</li>
                  <li>Rockaway Beach</li>
                  <li>Seaside</li>
                  <li>Tillamook</li>
                  <li>Tolovana Park</li>
                  <li>Vernonia</li>
                  <li>Warrenton</li>
                  <li>Wheeler</li>
                </ul>
              </Col>
              <Col md={6} className="flex-center">
                <div className="service-area"></div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row className="bg1">
        <Col>
          <Container id="contentContainer">
            <Row>
              <Col>
                <h1>What Our Customers Say About Us...</h1>
              </Col>
            </Row>
            <Row>
              <Col md={4} className="flex-center">
                <div className="bubble bubble-left">
                  Daikin Ductless Love Story. I would be happy to speak to any
                  perspective customers to let them know more about my unit and
                  how well it works.
                </div>
              </Col>
              <Col md={4} className="flex-center">
                <div className="bubble bubble-bottom">
                  ...they are efficient, professional and thorough...
                </div>
              </Col>
              <Col md={4} className="flex-center">
                <div className="bubble bubble-right">
                  Thank you for providing an excellent team...
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row className="bg2">
        <Col>
          <Container id="contentContainer">
            <Row>
              <Col>
                <h1>Reasons To Work With Us</h1>
              </Col>
            </Row>
            <Row className="reasons">
              <Col sm={6} md={3} className="flex-center">
                <div className="reasonCard">
                  <FontAwesomeIcon icon={faTruck} />
                  <h3>Expert Technicians</h3>
                  <p>Our team has over 35 years of knowledge and experience</p>
                </div>
              </Col>
              <Col sm={6} md={3} className="flex-center">
                <div className="reasonCard">
                  <FontAwesomeIcon icon={faTrophy} />
                  <h3>Same-Day Service</h3>
                  <p>When it's an emergency, we're here to help</p>
                </div>
              </Col>
              <Col sm={6} md={3} className="flex-center">
                <div className="reasonCard">
                  <FontAwesomeIcon icon={faUsers} />
                  <h3>Quality Guaranteed</h3>
                  <p>
                    If you're not{" "}
                    <a href="#conditions">
                      100% satisfied with your Carrier® installation, we'll
                      refund your money
                    </a>
                  </p>
                </div>
              </Col>
              <Col sm={6} md={3} className="flex-center">
                <div className="reasonCard">
                  <FontAwesomeIcon icon={faFileInvoiceDollar} />
                  <h3>Simple Financing</h3>
                  <p>Wide range of financing options for qualified customers</p>
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row id="conditions">
        <Col>
          <div className="container">
            100% Satisfaction Money Back Guarantee If for any reason you, the
            original purchaser, are not satisfied with your Carrier® system, the
            original installing contractor will repair the problem to your
            satisfaction or remove the Carrier® products and refund the purchase
            price, subject to the Conditions and Limitations listed below. This
            guarantee will remain in effect for one year after the original
            installation date. This guarantee is non-transferable
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Content
