import React from 'react'
import {
  Row,
  Col,
  Navbar,
  Nav,
  NavItem,
  NavLink} from 'reactstrap'

const CarrierNavRow = (props) => {

    return (
      <Row>
        <Col>
          <Navbar color="light" light expand="md" className="product-nav">
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink href="/products/gas-furnaces">Gas Furnaces</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/products/ductless-heat-pumps">Ductless Heat Pumps</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/products/boilers">Boilers</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/products/indoor-air-quality">Indoor Air Quality</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/products/thermostats">Thermostats</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/products/air-conditioning">Air Conditioning</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/products/air-scrubbers">Air Scrubbers</NavLink>
              </NavItem>
            </Nav>
          </Navbar>
        </Col>
      </Row>    
    )
}

export default CarrierNavRow
