import React from 'react'
import { Row, Col } from 'reactstrap'
import EmailUsButton from '../components/EmailUsButton'

const Footer = (props) => {

    return (
      <>
        <Row id="footer">
          <Col>
            <p className="lead">
              <b>We offer 24 Hour Emergency Service</b>
            </p>
            <p className="lead">
              <EmailUsButton />
            </p>
            <h3 className="call-us">
              <a href="tel://+15033252180">Call us on<br/>(503) 325-2180</a>
            </h3>
          </Col>
          <Col>
            <p>
              P&amp;L Johnson Mechanical, Inc<br/>
              429 A  Gateway Avenue<br/>
              Astoria, OR 97103
            </p>
          </Col>
          <Col>
            <p>
              In Business since: 1980<br/>
              Contractor ID: Oregon: 073615<br/>
              Washington: PLJOHMI09603
            </p>
          </Col>
        </Row>
      </>
    )
}

export default Footer
