import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Jumbotron } from 'reactstrap'
import Header from '../components/Header'
import NavRow from '../components/NavRow'
import EmailUsButton from '../components/EmailUsButton'

const Hero = (props) => {

    return (
      <Row id="hero">
        <div className="shadow">
          <Header /> 
          <NavRow />
          <Col className="flex-center">
            <Jumbotron>
              <Container fluid={false}>
              <h3>Heating - Gas, Heat Pumps &amp; Ductless</h3>
              <h3>Residential &amp; Commercial HVAC</h3>
              <h3>Air Conditioning</h3>
              <hr className="my-2" />
              <p className="lead">We're Your Heating & Cooling Experts in Greater Astoria & the North Coast since 1980</p>
              <hr className="my-2" />
              <p className="lead"><b>We offer 24 Hour Emergency Service</b></p>
              <p className="lead">
                <EmailUsButton />
              </p>
              <h3 className="call-us">
                <a href="tel://+15033252180">Call us on (503) 325-2180</a>  
              </h3>
              </Container>
            </Jumbotron>
          </Col>
        </div>

      </Row>
    )
}

export default Hero
