import React from 'react'
import { Row, Col } from 'reactstrap'
import Header from '../components/Header'
import NavRow from '../components/NavRow'

const Hero = (props) => {

    return (
      <Row id="mini-hero">
        <div className="shadow">
          <Header /> 
          <NavRow />
          <Col className="flex-center">
          </Col>
        </div>

      </Row>
    )
}

export default Hero
